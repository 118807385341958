@font-face {
    font-family: SandBrush;
    src: url("SandbrushPro.ttf") format("truetype");
  }
  @font-face {
    font-family: Shipwreck;
    src: url("SchoonerScript.ttf") format("truetype");
  }
  @font-face {
    font-family: WesternOld;
    src: url("WesternOld.ttf") format("truetype");
  }
  @font-face {
    font-family: IndustrialOld;
    src: url("IndustrialOld.ttf") format("truetype");
  }
  @font-face {
    font-family: Hillpark;
    src: url("Hillpark.ttf") format("truetype");
  }
  
  @font-face {
    font-family: Northead;
    src: url("Northead.ttf") format("truetype");
  }
  
  @font-face {
    font-family: Woodpecker;
    src: url("Woodpecker.ttf") format("truetype");
  }

  @font-face {
    font-family: Roboto;
    src: url("Roboto.ttf") format("truetype");
  }

  @font-face {
    font-family: BalooBhai-regular;
    src: url("BalooBhai-Regular.ttf") format("truetype");
  }
  @font-face {
    font-family: OpenSans;
    src: url("OpenSans.ttf") format("truetype");
  }
