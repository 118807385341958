// This file was generated by running 'ng generate @angular/material:m3-theme'.
// Proceed with caution if making changes to this file.

@use 'sass:map';
@use '@angular/material' as mat;

// Note: Color palettes are generated from primary: #3fa9f5, secondary: #666666, tertiary: #c5e5fc, neutral: #d1d1d1
$_palettes: (
  primary: (
    0: #000000,
    10: #001d32,
    20: #003352,
    25: #003f63,
    30: #004a75,
    35: #005787,
    40: #006399,
    50: #007dbf,
    60: #2297e2,
    70: #4cb2ff,
    80: #95ccff,
    90: #cde5ff,
    95: #e8f2ff,
    98: #f7f9ff,
    99: #fcfcff,
    100: #ffffff,
  ),
  secondary: (
    0: #000000,
    10: #1b1c1c,
    20: #303031,
    25: #3b3b3b,
    30: #464747,
    35: #525252,
    40: #5e5e5e,
    50: #777777,
    60: #919090,
    70: #acabab,
    80: #c7c6c6,
    90: #e4e2e2,
    95: #f2f0f0,
    98: #fbf9f8,
    99: #fefcfb,
    100: #ffffff,
  ),
  tertiary: (
    0: #000000,
    10: #001e2d,
    20: #123445,
    25: #1f3f51,
    30: #2b4a5d,
    35: #375669,
    40: #446275,
    50: #5c7b8f,
    60: #7695aa,
    70: #90afc5,
    80: #abcbe1,
    90: #c7e7fe,
    95: #e4f3ff,
    98: #f5faff,
    99: #fbfcff,
    100: #ffffff,
  ),
  neutral: (
    0: #000000,
    4: #0a0b0b,
    6: #101111,
    10: #1a1c1c,
    12: #1e2020,
    17: #292b2b,
    20: #2f3131,
    22: #333535,
    24: #383a3a,
    25: #3a3c3c,
    30: #454747,
    35: #515353,
    40: #5d5e5f,
    50: #767777,
    60: #909191,
    70: #ababab,
    80: #c6c6c6,
    87: #dadada,
    90: #e2e2e2,
    92: #e8e8e8,
    94: #eeeeed,
    95: #f1f1f0,
    96: #f4f4f3,
    98: #faf9f9,
    99: #fdfcfc,
    100: #ffffff,
  ),
  neutral-variant: (
    0: #000000,
    10: #141c24,
    20: #29313a,
    25: #343c45,
    30: #3f4851,
    35: #4b535d,
    40: #575f69,
    50: #6f7882,
    60: #89929c,
    70: #a3acb7,
    80: #bfc7d2,
    90: #dbe3ef,
    95: #e9f1fd,
    98: #f7f9ff,
    99: #fcfcff,
    100: #ffffff,
  ),
  error: (
    0: #000000,
    10: #410002,
    20: #690005,
    25: #7e0007,
    30: #93000a,
    35: #a80710,
    40: #ba1a1a,
    50: #de3730,
    60: #ff5449,
    70: #ff897d,
    80: #ffb4ab,
    90: #ffdad6,
    95: #ffedea,
    98: #fff8f7,
    99: #fffbff,
    100: #ffffff,
  ),
);

$_rest: (
  secondary: map.get($_palettes, secondary),
  neutral: map.get($_palettes, neutral),
  neutral-variant: map.get($_palettes,  neutral-variant),
  error: map.get($_palettes, error),
);
$_primary: map.merge(map.get($_palettes, primary), $_rest);
$_tertiary: map.merge(map.get($_palettes, tertiary), $_rest);




$light-theme: mat.define-theme((
  color: (
    theme-type: light,
    primary: $_primary,
    tertiary: $_tertiary,
    use-system-variables: true,
  ),
  typography: (
    use-system-variables: true,
   plain-family: 'Roboto',
   brand-family: 'Open Sans',
   bold-weight: 300,
   medium-weight: 200,
   regular-weight: 100,
  ),
));

@include mat.core();
@include mat.color-variants-backwards-compatibility($light-theme);

:root {
  @include mat.all-component-themes($light-theme);
  @include mat.system-level-colors($light-theme);
  @include mat.system-level-typography($light-theme);
}



